import React from "react"
import { Link, navigate, graphql } from "gatsby"
import Helmet from "react-helmet"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"
import RemoveHTML from "../components/removeHtml"
import Layout from "../components/layout"
import Seo from "../components/seo"
import useLiveControl from "./../controlled/useLiveControl"

const IndexPage = ({ data }) => {
  
  const customLink = (url, e) => {
    navigate(url)
  }
  const node = data.wpgraphql.pageBy

//  const { isStaging } = useLiveControl()
  return (
    <>
      <Helmet>
        <script type="application/ld+json" async={true}>
          {`
        {
          "@context": "https://schema.org/",
          "@type": "Organization",
          "name": "Regions Home Improvement Financing",
          "url": "https://enerbank.com/",
          "logo": {
            "@type": "ImageObject",
            "url": "https://gbdevpress.enerbank.com/wp-content/uploads/2024/08/regions-logo-trademark.jpg",
            "height": "83.00",
            "width": "280.00"
          },
          "contactPoint": [{
            "@type": "ContactPoint",
            "contactType": "customer service",
            "telephone": "888.390.1220",
            "contactOption": ""
          }],
        "sameAs": [
            "https://www.facebook.com/enerbank",
            "https://twitter.com/enerbank",
            "https://www.linkedin.com/company/enerbank-usa"
          ]
        }
        `}
        </script>
      </Helmet>

      <Layout>
        <Seo
          title={"Home Improvement Loans for Homeowners"}
          description={
            "EnerBank USA is America's home improvement lender of choice. Learn how we help contractors grow their business and homeowners have the home of their dreams."
          }
          canonical={"https://enerbank.com/"}
        />
        <Helmet>
          <body className="homepage" />
        </Helmet>
        <section
          className="banner-area"
          id="homepage-banner-section"
        >
          <div className="container banner-container"
            style={{
              backgroundImage:
                "url(" +
                "https://gbdevpress.enerbank.com/wp-content/uploads/2024/04/home-hero-horizontal.jpg" +
                ")",
            }}>
            <div className="relative banner-area-text-container">
              <div className="container text-center">
                <div className="identifier-container row-fluid">
                  <div className="element span12">
                    <div className="rhif-identifier span5">
                      <h4>HOME IMPROVEMENT FINANCING</h4>
                    </div>
                    <div className="right-angle span2"></div>
                  </div>
                </div>
                <h1 className="text-white text-left desktop" style={{ padding: "80 0" }}>
                  Home improvement lending<br /> you can count on
                </h1>
              </div>
            </div>
          </div>
          <div className="container text-center mobile">
            <h1 className="text-white text-left" style={{ padding: "80 0" }}>
              Home improvement lending you can count on
            </h1>
          </div>
        </section>
  <section className="service-area section-gap pt-5">
        <div className="container">
          <div className="col-md-12 mb-5">
            <h2 className="text-center">I'm interested in...</h2>
          </div>
          <div className="icon-cards row justify-content-center pb-5">
            <div className="col-lg-4">
              <Link className="card-link" to="/homeowner/#howtopay">
                <div className="card">         
                    <div className="card-image make-payment">
                        <img src="https://gbdevpress.enerbank.com/wp-content/uploads/2024/06/make-payment-1.png" className="" alt="Make a payment" />
                    </div>
                    <div className="card-body">
                        <h3>Making a Payment <FontAwesomeIcon className="fa-icon" icon="chevron-right" /></h3>
                    </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-4">
              <Link className="card-link" to="/loan-programs/#createloanprogram">
                <div className="card">
                    <div className="card-image">
                        <img src="https://gbdevpress.enerbank.com/wp-content/uploads/2024/06/create-loan-program-1.png" className="icon" alt="Create a loan program" width="50" />
                    </div>
                    <div className="card-body">
                        <h3>Creating a Loan Program <FontAwesomeIcon className="fa-icon" icon="chevron-right" /></h3>
                    </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-4">
              <Link className="card-link" to="/loan-programs/#joinloanprogram">
                <div className="card">
                    <div className="card-image">
                        <img src="https://gbdevpress.enerbank.com/wp-content/uploads/2024/06/join-loan-program.png" className="icon" alt="Join a loan program" />
                    </div>
                    <div className="card-body">
                        <h3>Joining a Loan Program <FontAwesomeIcon className="fa-icon" icon="chevron-right" /></h3>
                    </div>
                  </div>
                </Link>
            </div>
          </div>
          <div className="row justify-content-center">
              <div className="col-md-12 header-text">

                <p className="has-normal-font-size py-2">
                  At Regions Home Improvement Financing - formally EnerBank, our focus is on delivering the best home
                  improvement lending products and services. We&apos;re a
                  reliable home improvement lender, standing strong and
                  consistently growing — always striving to create an
                  exceptional customer experience. We truly know what it takes
                  to create an exceptional experience in home improvement
                  lending for the consumer, contractor, and industry partner.
                </p>

                <p className="has-normal-font-size py-2">
                  Our focus on the home improvement industry and proven track
                  record of helping contractors grow their businesses means you
                  can count on us to deliver quality loan products with a
                  superior customer experience. We balance our depth of
                  experience with our constant push to innovate.
                </p>

                <h2 className="text-center py-4 pb-5">
                  Trust us for home improvement lending
                </h2>

                <p className="has-normal-font-size py-2">
                  <span className="font-weight-bold">Stability.</span> Home
                  improvement lending is all we do. We&apos;ve funded over one million projects with billions of dollars since 2002, providing specialized support to home improvement professionals and homeowners.
                </p>

                <p className="has-normal-font-size py-2">
                  <span className="font-weight-bold">People.</span> We know that
                  people are our greatest asset, so we pride ourselves on taking
                  care of our customers. Whether it&apos;s a single point of
                  contact for home improvement contractors, or training all our
                  U.S.-based customer service teams to be helpful and
                  knowledgeable, we offer an excellent experience in home
                  improvement financing.
                </p>

                <p className="has-normal-font-size py-2">
                  <span className="font-weight-bold">Experience.</span> We live
                  and breathe home improvement lending, so we ensure your
                  customers not only have an exceptional customer experience, we
                  make sure they have a smooth transaction that makes you look
                  like a champ. When your customers are happy, they&apos;ll send
                  more business your way. It&apos;s a win for everybody.
                </p>
              </div>
            </div>
        </div>
        </section>
        <style
          dangerouslySetInnerHTML={{
            __html: [
              ".home-about-banner:after{",
              "background: url(" +
                node.home_section.aboutArea[0].addImage.sourceUrl +
                ") no-repeat center center/cover",
              "}",
            ].join("\n"),
          }}
        ></style>

        <section className="rds-marquee marquee-teal">
          <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-md-5 order-2 order-md-1">
              <h2 className="text-white mb-5">Refer a contractor</h2>
              <p className="text-white">Refer a contractor to Regions&apos; Express Loan Program<br/> and receive a $300 Amazon.com Gift Card!</p>
              <div className="row justify-content-center align-items-top pt-5 pb-3">
                <div className="col-md-6 mb-4 text-center">
                  <button type="button" className="btn btn-info btn-gray" onClick={() =>
                        customLink("https://homeimprovement.rhif.regions.com/homeowner-referral")}>I'm a Homeowner</button>
                </div>
                <div className="col-md-6 text-center">
                  <button type="button" className="btn btn-info btn-gray" onClick={() =>
                        customLink("https://homeimprovement.rhif.regions.com/contractor-referral")}>I'm in the Industry</button>
                </div>
              </div>
            </div>
            <div className="col-md-6 order-1 order-md-2 text-center">
              <img
                  src="https://gbdevpress.enerbank.com/wp-content/uploads/2024/06/amazon-300-gift-card-2.png"
                  className="mb-3"
                  alt="$300 Amazon gift card"
                />
            </div>
          </div>
          </div>
        </section>
        <section className="section-gap">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="text-center mb-4">
                    How home improvement financing with Regions works
                  </h2>
                  <p className="has-normal-font-size py-2">
                    It&apos;s simple: as a contractor, you meet with your client
                    to provide a bid for a home improvement project, and while
                    you&apos;re in the home, you can use our quick and easy
                    paperless application process to help homeowners get approved
                    in minutes for a specialized home improvement loan to pay for
                    the project. Your client then authorizes funding requests by
                    phone or text, and we deposit the funds directly into your
                    account, usually within 24 hours. Home improvement financing
                    powered by Regions means higher close rates, bigger jobs, and
                    a better customer experience.
                  </p>

                  <h3 className="">
                    Get the Edge Over Competitors
                  </h3>
                  <p className="has-normal-font-size py-2">
                    When you offer home improvement financing powered by Regions to
                    your customers, you make it easier for your clients to say
                    “yes” to getting the home of their dreams without having to
                    secure their own sources of financing. Contractor-offered
                    financing backed by a leader in customer service means you can
                    rest easy knowing your lending provider is taking just as good
                    care of your customers as you do.
                  </p>

                  <p className="has-normal-font-size py-2">
                    Contractors:{" "}
                    <a
                      className="eb-link"

                      href="/contractor-financing/"
                    >
                      Click Here
                    </a>{" "}
                    to get started offering payment options
                  </p>
                </div>
              </div>
            <div className="busiblock pt-5">
              <div className="icon-cards row justify-content-center">
                <div className="col-md-5 d-none">
                  <Link className="card-link" to="https://careers.regions.com/us/en/c/enerbank-jobs">
                    <div className="card">         
                        <div className="card-image">
                            <img src="https://gbdevpress.enerbank.com/wp-content/uploads/2024/06/briefcase.png" className="" alt="careers" />
                        </div>
                        <div className="card-body">
                            <h3>Careers <FontAwesomeIcon className="fa-icon" icon="chevron-right" /></h3>
                        </div>
                    </div>
                  </Link>
                </div>
                <div className="col-md-5">
                  <Link className="card-link" to="https://homeimprovement.rhif.regions.com/events">
                    <div className="card">
                        <div className="card-image">
                            <img src="https://gbdevpress.enerbank.com/wp-content/uploads/2024/06/calandar.png" className="icon" alt="events" />
                        </div>
                        <div className="card-body">
                            <h3>Events <FontAwesomeIcon className="fa-icon" icon="chevron-right" /></h3>
                        </div>
                    </div>
                  </Link>
                </div>
            </div>
             
            </div>
          </div>
        </section>
        <style
          dangerouslySetInnerHTML={{
            __html: [
              ".home-about-area2:after{",
              "background: url(" +
                node.home_section.aboutArea[1].addImage.sourceUrl +
                ") no-repeat top center/cover",
              "}",
            ].join("\n"),
          }}
        ></style>

        <section className="position-relative  rds-marquee bg-light-gray">
          <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-md-5">
              <img
                  src="https://gbdevpress.enerbank.com/wp-content/uploads/2024/06/homepage-relationship.jpg"
                  className="image"
                  alt="support"
                />
            </div>
            <div className="col-md-7">
              <h2 className="pb-3">The right payment options program makes all the difference</h2>
              <p>Whether you are an organization with a network of contractors, or an independent contractor, we have a loan program for you.</p>
              <p className="mt-5 mb-0"><button type="button" className="btn btn-info btn-green" onClick={() =>
                        customLink("/loan-programs/")}>Learn More About Our Loan Programs</button>
                </p>
            </div>
          </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query GET_POSTS {
    imageSharp(id: { eq: "898faa4a-edc2-53d4-9c2d-2d91c4901be8" }) {
      id
      original {
        height
        src
        width
      }
    }
    wpgraphql {
      pageBy(uri: "home") {
        content(format: RENDERED)
        title(format: RENDERED)

        home_section {
          aboutArea {
            addText
            fieldGroupName
            addImage {
              id
              sourceUrl
              altText
            }
            anchorBtn {
              fieldGroupName
              addLink {
                url
                title
                target
              }
            }
            addLogo {
              sourceUrl
              altText
            }
          }
          businessOption {
            businessBrif
            businessBlock {
              fieldGroupName
              businessButton {
                target
                title
                url
              }
              businessIcon {
                sourceUrl
                title
                altText
                caption
              }
              businessContent
            }
          }
        }
        top_banner {
          banner {
            backgroundImage {
              sourceUrl
              altText
            }
            bannerLinks {
              links {
                title
                url
              }
            }
          }
        }

        video_section {
          video {
            videoUrl
            videoBanner {
              sourceUrl
            }
          }
        }
      }
    }
  }
`

import React, { useState } from "react"
import { Modal, Button } from "react-bootstrap"
import HubspotForm from "react-hubspot-form"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import useLiveControl from "../controlled/useLiveControl"
import LiveControlSpinner from "../components/liveControlSpinner"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Card from "react-bootstrap/Card"

const ContractorApplication = () => {
  const { runningChecks } = useLiveControl()

  const [show, setShow] = useState(false)
  // const [message, setMessage] = useState(false)
  // const { register, handleSubmit, errors } = useForm();
  const message = false
  const handleClose = () => setShow(false)
  // const onSubmit = (data, e) => {
  //   var xhr = new XMLHttpRequest()
  //   var url =
  //     "https://api.hsforms.com/submissions/v3/integration/submit/381510/97ff0915-f670-4588-ad0b-1ae644a652b1"
  //   var sendData = {
  //     fields: [
  //       {
  //         name: "company",
  //         value: data.companyName,
  //       },
  //       {
  //         name: "firstname",
  //         value: data.firstName,
  //       },
  //       {
  //         name: "lastname",
  //         value: data.lastName,
  //       },
  //       {
  //         name: "email",
  //         value: data.email,
  //       },
  //       {
  //         name: "phone",
  //         value: data.phone,
  //       },
  //     ],
  //   }
  //   var finel_data = JSON.stringify(sendData)
  //   xhr.open("POST", url)
  //   xhr.setRequestHeader("Content-Type", "application/json")
  //   xhr.setRequestHeader("Access-Control-Allow-Origin", "*")
  //   xhr.setRequestHeader("Access-Control-Allow-Methods", "POST")
  //   xhr.onreadystatechange = function() {
  //     if (xhr.readyState === 4 && xhr.status === 200) {
  //       // alert(xhr.responseText.split('"', 4)[3]);
  //       setMessage(xhr.responseText.split('"', 4)[3])
  //       setShow(true)
  //       e.target.reset()
  //     } else if (xhr.readyState === 4 && xhr.status === 400) {
  //       setMessage(JSON.parse(xhr.responseText).errors[0].message)
  //       setShow(true)
  //     } else if (xhr.readyState === 4 && xhr.status === 403) {
  //       setMessage(xhr.responseText)
  //       setShow(true)
  //     } else if (xhr.readyState === 4 && xhr.status === 404) {
  //       setMessage(xhr.responseText)
  //       setShow(true)
  //     }
  //   }
  //   xhr.send(finel_data)
  // }

  if (runningChecks) return <LiveControlSpinner />

  return (
    <Layout>
      <Seo
        title={"Get Started"}
        description={"America's home improvement lender of choice"}
        canonical={"https://enerbank.com/contractor-application/"}
      />
      <Helmet>
        <body className="contractor-application-page html-hs-form" />
      </Helmet>
      <section className="container pb-0">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="text-center mb-5">
                Apply to Join the Regions Loan Program Today!
              </h1>
              <p>
                If you&#39;ve done the research and are ready to start offering
                payment options from Regions, then you&#39;ve come to
                the right place! Just fill out the information below and a
                member of our team will be in touch with any other information
                we may need to get you started.{" "}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="app-form pb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12 pb-5">
              <HubspotForm
                portalId="381510"
                formId="4c6365fc-02c3-48b8-88a7-65e3d09ebd05"
                onSubmit={() => this.handleFormSubmit}
                onReady={form => console.log("Form ready!")}
                loading={<div>Loading...</div>}
              />
            </div>

            <div className="col-md-12">
              <h3 className="text-center py-3 pt-4">
                Looking for More Information?
              </h3>
              <p className="text-center">
                Not quite ready to apply, but want to know more about our loan
                programs?{" "}
              </p>
              <p className="text-center pt-3">
                <Link to={"/sign-up/"} className="m-auto px-5 btn btn-info btn-green">
                  Learn More
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="rds-marquee marquee-teal">
        <div className="container">
          <div className="row">
            <div className="col-md-12 pb-5">
              <h2 className="text-center text-white pb-3">
                Getting Started with Regions is Easy and Fast
              </h2>
            </div>
            <div className="col-md-6 text-white">
              <h3 className="text-white pb-3">
                You could be offering Regions financing in as little as 3
                weeks!
              </h3>
              <p className="text-white">
                Experience a fast and helpful onboarding process with{" "}
                <strong>
                  {" "}
                  <br />
                  no sign-up fees
                </strong>
                . Then Regions can help you focus on growing your core business
                by giving your customers financing options that best meet their
                needs, like Same-As-Cash Loans or low monthly payments.
              </p>
            </div>
            <div className="col-md-6">
              <div className="card">
                <h3>Do You Qualify for Our Program?</h3>
                <p>
                  To offer payment options, you must meet the following
                  qualifications:
                </p>
                <ul className="fa-ul">
                  <li>
                    <i className="fa-li">
                      <FontAwesomeIcon
                        className="eb-green"
                        icon="check"
                      />
                    </i>
                    Your business has a focus on residential home remodeling
                  </li>
                  <li>
                    <i className="fa-li">
                      <FontAwesomeIcon
                        className="eb-green"
                        icon="check"
                      />
                    </i>
                    You have been in operation for at least three years
                  </li>
                  <li>
                    <i className="fa-li">
                      <FontAwesomeIcon
                        className="eb-green"
                        icon="check"
                      />
                    </i>
                    An average project size of at least $5,000
                  </li>
                  <li>
                    <i className="fa-li">
                      <FontAwesomeIcon
                        className="eb-green"
                        icon="check"
                      />
                    </i>
                    Annual residential remodeling sales of at least $500,000
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-white">
        <div className="container">
          <div className="row">
            <div className="col-md-12 pb-5">
              <h2 className="text-center pb-3">
                Technology That Makes Offering Payment Options Easy
              </h2>
            </div>
            </div>
            </div>
            <div className="container-lg">
            <div className="icon-cards row justify-content-center">
            <div className="col-md-2">
              <div className="card justify-content-inherit">
                <div className="card-image">
                  <img src="https://gbdevpress.enerbank.com/wp-content/uploads/2024/08/customer-prequalification.png" className="icon" alt="Customer Pre-Qualification" width="80" /> 
                </div>
                <div className="card-body">
                  <h3>Customer Pre-Qualification</h3>
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="card justify-content-inherit">
                <div className="card-image">
                  <img src="https://gbdevpress.enerbank.com/wp-content/uploads/2024/07/several-payment-options.png" className="icon" alt="A Choice of Payment Methods" /> 
                </div>
                <div className="card-body">
                  <h3>A Choice of Payment Methods</h3>
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="card justify-content-inherit">
                <div className="card-image">
                  <img src="https://gbdevpress.enerbank.com/wp-content/uploads/2024/06/high-approval-rates.png" className="icon" alt="Express Credit Decision" /> 
                </div>
                <div className="card-body">
                  <h3>Express Credit Decision</h3>
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="card justify-content-inherit">
                <div className="card-image">
                  <img src="https://gbdevpress.enerbank.com/wp-content/uploads/2024/05/Improve-cash-flow_1.png" className="icon" alt="Fast Payments" />
                </div>
                <div className="card-body">
                  <h3>Fast Payments</h3>
                </div>
              </div>
              </div>
              <div className="col-md-2">
                <div className="card justify-content-inherit">
                  <div className="card-image payment-options">
                    <img src="https://gbdevpress.enerbank.com/wp-content/uploads/2024/07/online-reporting-tracking-tool.png" className="icon" alt="Online Reporting & Tracking Tool" />
                  </div>
                  <div className="card-body">
                    <h3>Online Reporting & Tracking Tool</h3>
                  </div>
                </div>
              </div>
          </div>

        </div>
      </section>
      <section className="light-gray">
          <div className="container">
            <h2 className="text-center">
              Ready to work with a lender that puts you first?
            </h2>
          </div>
        <div className="container ready-to-work">
        <div className="icon-cards row justify-content-center">
            <div className="col-md-3">
              <div className="card justify-content-inherit">
                <div className="card-image">
                    <img src="https://gbdevpress.enerbank.com/wp-content/uploads/2024/07/dedicated-rm.png" className="icon" alt="Dedicated"/>
                  </div>
                <div className="card-body">
                  <h3 className="mb-2">Dedicated Relationshiop Manager</h3>
                  <p>
                  Acting as your single point of contact at the bank, they're familiar with your account and can help with many aspects of your business.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card justify-content-inherit">
                <div className="card-image">
                    <img src="https://gbdevpress.enerbank.com/wp-content/uploads/2020/10/free-marketing-tools.png" className="icon" alt="Marketing Tools" />
                </div>
                <div className="card-body">
                  <h3 className="mb-2">Marketing<br/> Tools</h3>
                  <p>
                  As a part of your program you gain access to high-quality marketing materials to help your business make a great impression.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card justify-content-inherit">
                <div className="card-image us-based">
                    <img src="https://gbdevpress.enerbank.com/wp-content/uploads/2024/06/us-based-lending-customer-service.png" class="icon" alt="U.S.-Based Customer Support" />
                </div>
                <div className="card-body">
                  <h3 className="mb-2">U.S. Based<br/> Customer Support</h3>
                  <p>
                  With hours beyond the usual 9 to 5, you can get the help you need when you need it.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card justify-content-inherit">
                <div className="card-image">
                    <img src="https://gbdevpress.enerbank.com/wp-content/uploads/2020/10/on-demand-training.png" className="icon" alt="On-Demand Training" />
                </div>
                <div className="card-body">
                  <h3 className="mb-2">On-Demand Training </h3>
                  <p>
                  Ensure that every member of your team is up to date on best practices to help ensure you're getting the most out of your payment options.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row">
            <article>
              <ul>
                <li className="mb-4 d-flex align-items-start">
                  <div className="wcf-img-li-box mr-3">
                    <img
                      src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/03/dedicated-rm.png"
                      alt="dedicated relationship manager"
                    />
                  </div>
                  <p className="ml-2">
                    <span className="font-weight-bold">
                      Dedicated Relationshiop Manager{" "}
                    </span>{" "}
                    — Acting as your single point of contact at the bank,
                    they&#39;re familiar with your account and can help with
                    many aspects of your business.
                  </p>
                </li>
                <li className="mb-4 d-flex align-items-start">
                  <div className="wcf-img-li-box mr-3">
                    <img
                      src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/03/marketing-tools.png"
                      alt="marketing tools"
                    />
                  </div>
                  <p className="ml-2">
                    <span className="font-weight-bold">Marketing Tools </span> —
                    As a part of your program you gain access to high-quality
                    marketing materials to help your business make a great
                    impression.
                  </p>
                </li>
                <li className="mb-4 d-flex align-items-start">
                  <div className="wcf-img-li-box mr-3">
                    <img
                      src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/03/us-based-customer-support.png"
                      alt="U.S. based customer support"
                    />
                  </div>
                  <p className="ml-2">
                    <span className="font-weight-bold">
                      U.S.-Based Customer Support{" "}
                    </span>{" "}
                    — With hours beyond the usual 9 to 5, you can get the help
                    you need when you need it.
                  </p>
                </li>
                <li className="mb-0 d-flex align-items-start">
                  <div className="wcf-img-li-box mr-3">
                    <img
                      src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/03/on-demand-trainning.png"
                      alt="on demand-training"
                    />
                  </div>
                  <p className="ml-2">
                    <span className="font-weight-bold">
                      On-Demand Training{" "}
                    </span>{" "}
                    — Ensure that every member of your team is up to date on
                    best practices to help ensure you&#39;re getting the most
                    out of your payment options.
                  </p>
                </li>
              </ul>
            </article>
          </div> */}
        </div>
      </section>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body
          className="p-4 mt-5 text-center"
          dangerouslySetInnerHTML={{ __html: message }}
        />
        <Modal.Footer className="border-0">
          <Button variant="primary mb-3 mx-auto" onClick={handleClose}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  )
}

export default ContractorApplication

// export const query = graphql`
//     query($databaseId: ID!) {
//         wpgraphql {
//             page(id: $databaseId, idType: DATABASE_ID) {
//                 title
//                 date
//                 content(format: RENDERED)
//                 featuredImage {
//                     altText
//                     title(format: RENDERED)
//                     mediaItemUrl
//                     slug
//                 }
//             }
//         }
//     }
// `

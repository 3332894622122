import React from "react"

import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Helmet } from "react-helmet"
import { Tabbordion, TabPanel, TabLabel, TabContent } from "react-tabbordion"
import "react-tabbordion/demo/accordion.css"
import useLiveControl from "../controlled/useLiveControl"

// accordian
const blockElements = {
  animator: "accordion-animator",
  content: "accordion-content",
  panel: "accordion-panel",
  label: "accordion-title",
}

const CareerPage = ({ data }) => {

  const { isStaging } = useLiveControl()

  return (
    <Layout>
      <Seo
        title={"Contact Us"}
        description={
          "Do you have questions about our home improvement payment options? Contact us through our contact page today."
        }
        canonical={"https://www.regions.com/small-business/contractor-financing/contractor-support"}
      />
      <Helmet>
            <body id="rebrand" className="contact-page" />
          </Helmet>
            <section 
            className="banner-area"
            id="contact-page-banner-section">
            <div className="container banner-container"
                style={{
                            backgroundImage:
                            "url(" +
                            "https://gbdevpress.enerbank.com/wp-content/uploads/2020/11/Contact.jpg" +
                            ")",
                        }}
                >
            <div className="relative banner-area-text-container">
                    <div className="container text-center">
                        <div className="identifier-container row-fluid">
                            <div className="element span12">
                                <div className="rhif-identifier span5">
                                    <h4>HOME IMPROVEMENT FINANCING</h4>
                                </div>
                                <div className="right-angle span2"></div>
                            </div>
                        </div>
                        <h1 className="text-white text-left desktop" style={{ padding: "80 0" }}>
                            Let's connect
                        </h1>
                    </div>
                </div>
            </div>
            <div className="container text-center mobile">
                <h1 className="text-white text-left" style={{ padding: "80 0" }}>
                Let's connect
                </h1>
            </div>
        </section>
      <section className="pb-5 accordion-list bg-light-gray">
          <div className="container">
            <div className="col-12">
            <h2 className="text-center pb-3">Contact Us</h2>
            </div>
            <div className="row">
          <Tabbordion
            blockElements={blockElements}
            animateContent={"height"}
            className="accordion loan_offer mx-4 mx-lg-5"
            name="accordion"
          >
            {data.wpgraphql.page.contact.supportSection.map((item, i) => {
              return (
                <TabPanel key={item.fieldGroupName + i} className="col-12">
                  {/* <h3 className="text-blue supportHeading">{item.supportHeading}</h3> */}
                  <TabLabel
                    className="w-100 text-left btn btn-link"
                    dangerouslySetInnerHTML={{ __html: item.supportHeading }}
                  />
                  <TabContent>
                    <div
                      className="mb-4"
                      dangerouslySetInnerHTML={{ __html: item.supportDetails }}
                    />
                    {item.innerSection &&
                      item.innerSection.map((item, j) => (
                        <div key={item.fieldGroupName + j}>
                          <div className="bg-grey py-2 px-3 font-weight-bold supportHeading">
                            {item.innerSectionHeading}
                          </div>
                          <div
                            className="py-4 mb-3 innerSectionDetails"
                            dangerouslySetInnerHTML={{
                              __html: item.innerSectionDetails,
                            }}
                          />
                        </div>
                      ))}
                  </TabContent>
                </TabPanel>
              )
            })}
          </Tabbordion>
        </div>
          </div>   
      </section>
    </Layout>
  )
}
export default CareerPage

export const query = graphql`
  query($databaseId: ID!) {
    wpgraphql {
      page(id: $databaseId, idType: DATABASE_ID) {
        title
        date
        content(format: RENDERED)
        featuredImage {
          node {
            altText
            title(format: RENDERED)
            mediaItemUrl
            slug
          }
        }

        top_banner {
          fieldGroupName
          banner {
            fieldGroupName
            backgroundImage {
              sourceUrl
              altText
            }
          }
        }
        contact {
          supportSection {
            supportDetails
            supportHeading
            innerSection {
              innerSectionDetails
              innerSectionHeading
              fieldGroupName
            }
            fieldGroupName
          }
        }
      }
    }
  }
`

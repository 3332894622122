import { Link, navigate } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../../components/layout"
import iconDollarTalk from "../../images/dollar-talk-img.png"
import Seo from "./../../components/seo"
import { Button } from "react-bootstrap"
import { Tabbordion, TabPanel, TabLabel, TabContent } from "react-tabbordion"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import useLiveControl from "./../../controlled/useLiveControl"
import HubspotForm from "react-hubspot-form"

const customLink = (url, e) => {
  navigate(url)
}

// accordian
const blockElements = {
  animator: "accordion-animator",
  content: "accordion-content",
  panel: "accordion-panel",
  label: "accordion-title",
}


const ContractorsFinancingPage = () => {
 // const { isStaging } = useLiveControl()
  return (
    <Layout>
      <Seo
        title="Contractor Financing for Home Improvement"
        description="Contractor financing helps home improvement contractors offer customers an easy way to pay for their project. Close more deals with financing from EnerBank USA!"
        canonical={"https://enerbank.com/contractor-financing/"}
      />
      <Helmet>
        <body className="page-contractors" />
        <script type="application/ld+json" async={true}>
          {`{
          "@context": "https://schema.org/",
          "@type": "Product",
          "name": "Contractor Financing for Home Improvement",
          "image": "",
          "brand": {
            "@type": "Brand",
            "name": "Regions Home Improvement Financing"
          }
          }`}
        </script>
      </Helmet>
        <section 
        className="banner-area"
        id="home-improvement-financing-banner-section">
          <div className="container banner-container"
              style={{
                        backgroundImage:
                          "url(" +
                          "https://gbdevpress.enerbank.com/wp-content/uploads/2022/07/contractor-financing-hero-1-scaled.jpeg" +
                          ")",
                      }}
              >
            <div className="relative banner-area-text-container">
                    <div className="container text-center">
                      <div className="identifier-container row-fluid">
                        <div className="element span12">
                            <div className="rhif-identifier span5">
                                <h4>HOME IMPROVEMENT FINANCING</h4>
                            </div>
                            <div className="right-angle span2"></div>
                        </div>
                      </div>
                      <h1 className="text-white text-left desktop" style={{ padding: "80 0" }}>
                      Contractor financing<br/> for home improvement
                      </h1>
                    </div>
                  </div>
            </div>
            <div className="container text-center mobile">
            <h1 className="text-white text-left" style={{ padding: "80 0" }}>
             Contractor financing for home improvement
            </h1>
          </div>
        </section>
      <section
        className="paragraph-text-block pt-0"
        id="contractor-financing-paragraph-text-block"
      >
        <div className="container">
          <div className="justify-content-center">
            <div className="pt-5">
              <div>
                <p className="">
                  Looking to grow your business while providing homeowners with
                  an exceptional customer experience? Offering contractor financing 
                  for clients through Regions Home Improvement Financing is the answer. With over two decades of deep experience in the home
                  improvement industry, we speak your language and understand
                  your challenges. Working together, we help your customers&#39;
                  dreams come true while giving you a true leg-up on the
                  competition.
                </p>
                <p className="">
                  We provide contractor financing for all aspects of home improvement, including HVAC, roofing, exterior remodel, foundation repair, interior remodel, residential painting, windows and doors, hardscape, fencing, and much more.
                </p>
                <p className="">
                Regions is the trusted home improvement lender, providing the
                  ease and convenience your customers want to get the home of
                  their dreams.
                </p>
              </div>
              <p className="text-center">
                <button
                  className="btn btn-info btn-green mt-5"
                  onClick={() =>
                    customLink("#get-contractor-financing")}
                >
                  Get Contractor Financing
                </button>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section
        className="container-fluid marquee-green"
        id="contractor-financing-left-image-right-text-section"
      >
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-md-6">
                <img className="image" src="https://gbdevpress.enerbank.com/wp-content/uploads/2023/12/service-stability-convenience.jpg" />
              </div>
              <div className="col-md-6">
                <h2 className="text-white mb-4">America&apos;s Home Improvement Lender of Choice</h2>
                  <p className="text-white">
                    We&apos;ve built our whole business around the unique
                    needs of home improvement contractors like you. Regions gives 
                    you the strength and service of a stable and regulated bank,
                    along with the ingenuity and ease-of-use that comes with
                    decades of experience in your world.
                  </p>
              </div>
            </div>
          </div>
      </section>
      <section className="paragraph-text-block bg-light-gray">
        <span id="get-contractor-financing"></span>
        <span id="get-started"></span>
        <span id="request-more-info"></span>
        <div className="container">
          <div className="justify-content-center">
            <div className="pb-5">
              <div className="section-container">
                <div className="section-head">
                  <h2 className="pb-5 text-center">
                    What is contractor financing?
                  </h2>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <p>
                    Contractor financing is a way for home improvement
                    professionals like you to work with a lender like us to
                    offer your homeowner customers an easy way to pay for their
                    project. Unlike other forms of financing, our payment
                    options are tailored to home improvement projects, and make
                    it easy for your customer to apply for the loan that best
                    suits their needs.
                  </p>
                  <p>
                    Why offer financing from Regions? When homeowners have to
                    look for ways to pay for their project, they&apos;re more
                    likely to shop around for another bid, rather than sticking
                    with you. If you offer a great way to pay, you&apos;ll close
                    more deals. It&apos;s that simple.
                  </p>
                  <p>
                    And our loan products are designed specifically for home
                    improvement financing — you&apos;ll get the peace of mind of 
                    working with a finance provider that&apos;s funded more than 
                    $14 billion in home improvement projects.
                  </p>
                </div>
                <div className="col-md-6">
                  <HubspotForm
                    portalId="381510"
                    formId="d5ed3b50-8f3b-421d-8209-6509568a5d01"
                    onSubmit={() => this.handleFormSubmit}
                    onReady={form => console.log("Form ready!")}
                    loading={<div>Loading...</div>}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="paragraph-text-block">
        <div className="container">
          <div className="justify-content-center">
            <div className="">
              <div className="section-container">
                <div className="section-head">
                  <h2 className="pb-5 text-center">
                    How do contractors offer financing to their customers?
                  </h2>
                </div>
              </div>
              <p className="pb-5">
                You&apos;ve most likely heard of other contractors offering
                their customers payment options, like Same-As-Cash financing.
                Maybe you&apos;ve even asked yourself, “How can I offer
                financing to my customers?” It turns out the process is easy.{" "}
              </p>
              <div className="infographic pb-5">
                <img
                  className="desktop"
                  src="https://gbdevpress.enerbank.com/wp-content/uploads/2024/07/RHIF-infographic-desktop.png"
                  alt="contractor financing infographic"
                />
                <img
                  className="mobile"
                  src="https://gbdevpress.enerbank.com/wp-content/uploads/2024/07/RHIF-infographic-mobile.png"
                  alt="contractor financing infographic"
                />
              </div>
              <p className="pt-3">
                To get started
                <Link className="font-weight-bold" to="#get-started">
                  &nbsp;click here
                </Link>
                . Or you can call us at{" "}
                <a className="font-weight-bold" href="tel:888.704.5376">
                  888.704.5376
                </a>{" "}
              </p>

              <p>
              In as little as three weeks, you can join the thousands of contractors 
              who offer competitive and attractive financing options from Regions to 
              their customers. During your onboarding, we&apos;ll provide training and 
              helpful tools to make sure you&apos;re set up for success.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-light-gray">
        <div class="container">
          <div className="row justify-content-center align-items-center">
                <div className="col-md-6">
                  <div className="container">
                      <div className="column-text">
                        <h2 className="mb-5">
                          What to Look for in a home improvement lender
                        </h2>
                        <p>
                          Is your loan provider reliable? Do they have the right
                          loan products? Can they fulfill their funding
                          commitments? These and many others are ones you should
                          ask when selecting a loan provider. 
                        </p>
                      </div>
                    </div>
                  </div>
                <div className="col-md-6">
                  <img className="image" src="https://gbdevpress.enerbank.com/wp-content/uploads/2024/07/home-improvement.jpg" alt="home improvement lender" />
                </div>
          </div>
        </div>
      </section>
      <section className="rds-marquee marquee-teal">
        <div className="container">
          <div className="justify-content-center">
            <div className="pt-5 pb-5">
              <div className="section-container">
                <div className="section-head">
                  <h2 className="pb-5 text-white text-center">
                    Why Should Contractors Offer Financing?
                  </h2>
                </div>
              </div>
              <div className="row justify-content-center align-items-center">
                  <div className="col-md-12">
                    <ul className="fa-ul text-white">
                      <li className="text-white mb-4">
                        <span className="fa-li"><FontAwesomeIcon className="fa-icon" icon="check" /></span>
                        Increase the chances for business expansion: About 50% of
                        customers finance projects over $5,000. When you advertise
                        financing to your customers, you could see a significant
                        increase in leads.
                      </li>
                      <li className="text-white mb-4">
                        <span className="fa-li"><FontAwesomeIcon className="fa-icon" icon="check" /></span>
                        Stay ahead of your competitors: When you don&apos;t offer home
                        improvement financing options, you could be leaving behind 50%
                        of your business. Those lost customers end up with a
                        competitor, leaving you to settle for the leftovers. By
                        offering financing options, you can retain those customers and
                        stay ahead of the competition.
                      </li>
                      <li className="text-white mb-4">
                        <span className="fa-li"><FontAwesomeIcon className="fa-icon" icon="check" /></span>
                        Provide customers with options: Financing options let you
                        offer various products at different price points that can fit
                        almost any budget — a “good, better, best” approach to your
                        sales process. When customers see they have greater spending
                        power and more flexibility in their choices, they can be more
                        confident in their decisions and often spend more.
                      </li>
                      <li className="text-white mb-4">
                        <span className="fa-li"><FontAwesomeIcon className="fa-icon" icon="check" /></span>
                        <span className="font-wight-bold">Boost your close rate:</span>
                      Offering the right payment options can almost double your
                      close rate. We&apos;ve found that companies with a 25%
                      baseline can increase their close rate to 44%<sup>*</sup> when they offer
                      a choice of a same-as-cash loan and a low monthly payment
                      loan.
                    </li>
                  </ul>
                  <p className="text-center mb-4 text-white">
                    <em>
                      <sup>*</sup>Based on the Brickyard Study commissioned by Regions, 2018.
                    </em>
                  </p>

                  <p className="text-center pt-5">
                    <Button variant="" className="btn btn-gray" onClick={() =>
                          customLink("#request-more-info")
                        }>Request More Information Here</Button>
                  </p>
                </div>
                
              </div>
              
            </div>
          </div>
        </div>
      </section>
        <section className="paragraph-text-block">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-md-12">
                <h2 className="pb-5 text-center">
                        Best Practices for Offering Customer Financing as a
                        Contractor
                </h2>
              </div>
              </div>
            <div className="row justify-content-center align-items-center">
              <div className="col-md-12">
                <p>
                  No matter how experienced you are as a contractor, our best
                  practices for selling customer financing can help boost your
                  business even more:
                </p>
                <ul>
                  <li className="mb-4">
                    <span className="font-weight-bold">
                      Make sure everyone knows.
                    </span>{" "}
                    If potential customers know from their very first interactions
                    with you — online, flyers, or on your trucks — that financing
                    is an option, they&apos;ll be much more likely to make your phone
                    ring.
                  </li>
                  <li className="mb-4">
                    <span className="font-weight-bold">
                      Keep the communication flowing.
                    </span>{" "}
                    After your customer sees your marketing and calls to schedule
                    an appointment, keep your messaging consistent and make sure
                    they know financing will be an option they can learn more
                    about during their appointment.
                  </li>
                  <li className="mb-4">
                    <span className="font-weight-bold">
                      Present the options confidently.
                    </span>{" "}
                    You already know your skills and your services like the back
                    of your hand; make sure you know just as much about the
                    options your clients have for paying you! Offering these
                    options confidently as a natural part of your sales and
                    closing process instills confidence and will help you close
                    more, and higher ticket deals.
                  </li>
                  <li className="">
                    <span className="font-weight-bold">
                      Make the setup easy for the customer.
                    </span>{" "}
                    Make the process of closing the deal as easy and painless as
                    possible. Regions&apos; simple paperless application methods
                    make closing deals quick and easy.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      <section id="faqs" className="contractor-faqs bg-light-gray">
        <div className="container">
          <h2 className="text-center pb-5">
            Frequently asked questions
          </h2>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
            <Tabbordion
              blockElements={blockElements}
              animateContent={"height"}
              mode={"toggle"}
              className="regions-accordion"
              name="accordion"
          >
              <TabPanel>
                  <TabLabel className="btn btn-link">
                      <div className="row-fluid">
                          <div className="col-11">
                              <span className="title-link">How does contractor financing work?</span>
                          </div>
                          <div className="col-1">
                              <span className="accordion-icon"></span>
                          </div>
                      </div>
                  </TabLabel>
                  <TabContent>
                      <p>
                      When you work with a home improvement lender, they take care of the financing side of the business so you can focus on the work at hand. When providing a bid, mention the availability of payment options, and have the homeowner apply for a loan. Depending on the lender you work with, the proceeds of the loan may go directly to you, or they may go the homeowner who then pays you. You can then begin the project and start requesting the needed funds. The customer will then make their payments to the lender.</p>
                  </TabContent>
              </TabPanel>
              <TabPanel>
                  <TabLabel className="btn btn-link">
                      <div className="row-fluid">
                          <div className="col-11">
                              <span className="title-link">What are the best contractor financing options?</span>
                          </div>
                          <div className="col-1">
                              <span className="accordion-icon"></span>
                          </div>          
                      </div>
                  </TabLabel>
                  <TabContent>
                      <p>
                      There are a lot of options out there when it comes to picking a contractor financing provider. It's important to ensure that whoever you work with is stable, secure, and has experience in the home improvement industry. They should also provide a variety of loans that match both the needs of your business and your customers.
                      </p>
                  </TabContent>
              </TabPanel>
              <TabPanel>
                  <TabLabel className="btn btn-link">
                      <div className="row-fluid">
                          <div className="col-11">
                              <span className="title-link">How do contractors sign up to offer financing?</span>
                          </div>
                          <div className="col-1">
                              <span className="accordion-icon"></span>
                          </div>
                      </div>
                  </TabLabel>
                  <TabContent>
                      <p>
                      It depends on the lender with which you want to work. Some may have parameters on who can join, such as being part of an association, while others are open to all contractors. You may have to meet certain criteria, such as years in business or annual sales, to qualify to join certain programs. Regions helps you through the process at every step.</p>
                  </TabContent>
              </TabPanel>
              <TabPanel>
                  <TabLabel className="btn btn-link">
                      <div className="row-fluid">
                          <div className="col-11">
                              <span className="title-link">What size home improvement projects are best to finance?</span>
                          </div>
                          <div className="col-1">
                              <span className="accordion-icon"></span>
                          </div>
                      </div>
                  </TabLabel>
                  <TabContent>
                      <p>Payment options are a great fit for any size of home improvement project! Whether it's customers who were planning on paying with cash or homeowners who don't know where they'll get the money they need, there are benefits for everyone. Research shows that over 55% of home improvement projects are financed in some way<sup>*</sup>.</p>
                  </TabContent>
              </TabPanel>
              <TabPanel>
                  <TabLabel className=" btn btn-link">
                      <div className="row-fluid">
                          <div className="col-11">
                              <span className="title-link">How do contractor financing companies make money?</span>
                          </div>
                          <div className="col-1">
                              <span className="accordion-icon"></span>
                          </div>
                      </div>
                  </TabLabel>
                  <TabContent>
                      <p>
                      The main source of income for most home improvement financing companies is the interest on homeowner's loans. Some providers and loan products may also require fees, such as charging a contractor more for loans that have a lower or no interest rate. Loans with lower interest rates are more attractive to your customers, making them more likely to choose you for the project.</p>
                  </TabContent>
              </TabPanel>
          </Tabbordion>
            </div>
          </div>
          <p>
            <small>
              <sup>*</sup>
              <strong>
                Based on the HIRI TFG Monthly report, released October 2023
              </strong>
            </small>
          </p>
        </div>
      </section>
    </Layout>
  )
}

export default ContractorsFinancingPage

import React, { useState, useEffect, useRef } from "react"
import { Modal, Button } from "react-bootstrap"
import { graphql, Link } from "gatsby"
import Helmet from "react-helmet"
import moment from "moment"
import HubspotForm from "react-hubspot-form"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import defaultImg from "../images/default-cover-1.jpg"
import BlogArchive from "../components/blogArchive"
import { CAMPAIGN_POSTS, DEV_ONLY_PATHS } from "./../controlled/index"
import useLiveControl from "../controlled/useLiveControl"

const BlogPage = ({ data }) => {
  const [show, setShow] = useState(false)
  // const [message, setMessage] = useState(false)
  const message = false

  const handleClose = () => setShow(false)

  // const onSubmitEmail = (data, e) => {
  //   var xhr = new XMLHttpRequest()
  //   var url =
  //     "https://api.hsforms.com/submissions/v3/integration/submit/381510/a14474d3-8b5a-4392-9713-c806454971a6"
  //   var sendData = {
  //     fields: [
  //       {
  //         name: "email",
  //         value: data.email,
  //       },
  //     ],
  //   }
  //   var finel_data = JSON.stringify(sendData)
  //   xhr.open("POST", url)
  //   xhr.setRequestHeader("Content-Type", "application/json")
  //   xhr.setRequestHeader("Access-Control-Allow-Origin", "*")
  //   xhr.setRequestHeader("Access-Control-Allow-Methods", "POST")
  //   xhr.onreadystatechange = function() {
  //     if (xhr.readyState === 4 && xhr.status === 200) {
  //       // alert(xhr.responseText.split('"', 4)[3]);
  //       setMessage(xhr.responseText.split('"', 4)[3])
  //       setShow(true)
  //       e.target.reset()
  //     } else if (xhr.readyState === 4 && xhr.status === 400) {
  //       setMessage(JSON.parse(xhr.responseText).errors[0].message)
  //       setShow(true)
  //     } else if (xhr.readyState === 4 && xhr.status === 403) {
  //       setMessage(xhr.responseText)
  //       setShow(true)
  //     } else if (xhr.readyState === 4 && xhr.status === 404) {
  //       setMessage(xhr.responseText)
  //       setShow(true)
  //     }
  //   }
  //   xhr.send(finel_data)
  // }

  const isValid = str => {
    return !/[~!#$%\^&*()+=\-\[\]\\';,/{}|\\":<>\?]/g.test(str)
  }

  const { isStaging } = useLiveControl()

  const isCampaignPost = slug => {
    return CAMPAIGN_POSTS.includes(slug)
  }

  const blogs = data.wpgraphql.posts.edges
  const filtereBlogs = blogs.filter(blog => !isCampaignPost(blog?.node?.slug))

  const currentBlogIndex = filtereBlogs.findIndex(
    blog => blog.node.id === data.wpgraphql.post.id
  )
  const nextBlogPost = filtereBlogs[currentBlogIndex - 1]
  const previousBlogPost = filtereBlogs[currentBlogIndex + 1]

    const [isOpen, setIsOpen] = useState(false)

    const paragraphStyles = {
      WebkitLineClamp: 3,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      display: '-webkit-box'
    }

    const [showReadMoreButton, setShowReadMoreButton] = useState(false)

    const ref = useRef(null)

    useEffect(() => {
      if (ref.current) {
        setShowReadMoreButton(
          ref.scrollHeight != ref.current.clientHeight
        )
      }
    }, [])

  return (
    <Layout>
      <Seo
        title={data.wpgraphql.post.title}
        description={
          "Welcome to the EnerBank USA blog! Learn more about how to grow your business."
        }
        canonical={"https://enerbank.com/blog/"}
      />

      {data.wpgraphql.post.slug ===
        "3-ways-contractors-use-social-media-to-increase-sales" && (
        <Helmet>
          <script type="application/ld+json" async={true}>
            {`{
            "@context": "https://schema.org/",
            "@type": "Article",
            "author": {
            "@type": "Organization",
            "name": "EnerBank USA"
            },
            "headline": "3 Ways Contractors Use Social Media to Increase Sales",
            "image": "https://gbdevpress.enerbank.com/wp-content/uploads/2022/10/AdobeStock_292834318-scaled.jpeg",
            "datePublished": "2022-11-08T07:00:00.000Z",
            "publisher": {
            "@type": "Organization",
            "name": "EnerBank USA"
            },
            "description": "When was the last time you got a lead from Facebook or another social platform? Social media is a powerful tool that you shouldn&#39;t overlook when you&#39;re trying to boost business. Whether you want to pick up seasonal customers or simply capture new leads, it&#39;s easy to reach your target audience. However, social media can be difficult and sometimes intimidating to manage, especially with so much existing noise in those spaces. But don&#39;t fret — here are three ways contractors can use social media to increase sales:"
            }`}
          </script>
        </Helmet>
      )}

      <section id="blogDetail" className="bg-light-gray section-gap blogpost">
        <div className="container">
          {/* <h1 dangerouslySetInnerHTML={{ __html: data.wpgraphql.post.title }} /> */}
          <div className="row mb-0">
            <div className="col-12 col-md-6"></div>
            <div className="col-12 col-md-6">
              <div className="row"></div>
            </div>
          </div>

          <div className="row align-items-start">
            <div className="col-lg-8 mt-4 mt-lg-0">
              <h1
                className="h2"
                dangerouslySetInnerHTML={{ __html: data.wpgraphql.post.title }}
              />
              <div className="custom-author-container">
                {console.log(
                  "Custom Author Name: " +
                    data.wpgraphql.post.blog.blogField.ghostWriter.name
                )}
                {data.wpgraphql.post.blog.blogField.ghostWriter.image &&
                data.wpgraphql.post.blog.blogField.ghostWriter.name ? (
                  <div className="ghost-writer my-3">
                    <div className="ghost-writer-pic">
                      <img
                        src={
                          data.wpgraphql.post.blog.blogField.ghostWriter.image
                            .sourceUrl
                        }
                        alt={
                          data.wpgraphql.post.blog.blogField.ghostWriter.image
                            .altText
                        }
                      />
                    </div>
                    <h4 className="">
                      <span className="">
                        by{" "}
                        <span className="text-blue ghost-writer-author">
                          <a
                            href={
                              "#" +
                              data.wpgraphql.post.blog.blogField.ghostWriter.name
                                .replaceAll(" ", "-")
                                .toLowerCase()
                            }
                          >
                            {
                              data.wpgraphql.post.blog.blogField.ghostWriter
                                .name
                            }
                          </a>
                        </span>{" "}
                        | {moment(data.wpgraphql.post.date).format("MMMM D, Y")}
                      </span>
                    </h4>
                  </div>
                ) : (
                  <h4 className="f-bold mb-3">
                    Posted{" "}
                    {moment(data.wpgraphql.post.date).format("MMMM D, Y")} by{" "}
                    Regions Home Improvement Financing
                  </h4>
                )}
              </div>
              <div className="blog-template-cover">
                {data.wpgraphql.post.featuredImage && (
                  <img
                    src={data.wpgraphql.post.featuredImage.node.mediaItemUrl}
                    alt={data.wpgraphql.post.featuredImage.node.altText}
                    className="img-fluid image w-100 mb-4"
                  />
                )}
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.wpgraphql.post.content,
                }}
                className="blog-body"
              />
            </div>
            <div className="col-lg-4">
              <div className="row">
                <div className="col-12 col-md-8 d-none">
                  <label htmlFor="serchBlog">Search Blog</label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="serchBlog"
                      id="serchBlog"
                      className="form-control border-right-0"
                      placeholder="Search Blog"
                    />
                    <div className="input-group-append">
                      <button className="btn border border-left-0">
                        <FontAwesomeIcon icon={faSearch} />
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-12 ">
                  <div id="subscribe">
                    <HubspotForm
                      portalId="381510"
                      formId="7a74c670-5f41-4932-9d48-a5701dc78380"
                      onSubmit={() => this.handleFormSubmit}
                      onReady={form => console.log("Form ready!")}
                      loading={<div>Loading...</div>}
                      onFormReady={(form, e) => {
                        document.querySelector("input").addEventListener(
                          "keypress",
                          function(event) {
                            const character = String.fromCharCode(
                              event.KeyboardEvent.keyCode
                            )
                            return isValid(character)
                          },
                          false
                        )
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="my-3 custom-mtop">
                <BlogArchive />
              </div>

              <div className="rounded growBussiness">
                {/* <p>Ready to Grow Your Business with EnerBank USA? Start Here.</p> */}
                <HubspotForm
                  portalId="381510"
                  formId="e5acddb0-93f6-48b2-89d9-bd9714b2df24"
                  onSubmit={() => this.handleFormSubmit}
                  onReady={form => console.log("Form ready!")}
                  loading={<div>Loading...</div>}
                />
              </div>       
                <div className="recent-posts mt-5 card">
              <h3 className="mb-4">Recent Posts</h3>
              {(() => {
                for (var i = 0; i < blogs.length; i++) {
                  if (
                    blogs[i].node.blog.blogField.private === true ||
                    isCampaignPost(blogs[i]?.node?.slug) ||
                    (!isStaging &&
                      DEV_ONLY_PATHS.includes("/blog/" + blogs[i]?.node?.slug))
                  ) {
                    blogs.splice(i, 1)
                  }
                }
              })()}
              {blogs.map((item, i) => {
                return (
                  i < 3 && (
                    <div key={item.node.id}>
                      <div className="row mb-3">
                        <div className="col-4">
                          {item.node.featuredImage ? (
                            <a
                              href={"/blog/" + item.node.slug + "/"}
                              rel="noreferrer"
                              className="p-0"
                            >
                              <img
                                src={item.node.featuredImage.node.sourceUrl}
                                alt={item.node.featuredImage.node.altText}
                              />
                            </a>
                          ) : (
                            <a
                              href={"/blog/" + item.node.slug + "/"}
                              rel="noreferrer"
                            >
                              <img src={defaultImg} alt={"DefaultImage"} />
                            </a>
                          )}
                        </div>
                        <div className="col-8">
                          <h4 className="text-blue">
                            <Link
                              to={"/blog/" + item.node.slug + "/"}
                              dangerouslySetInnerHTML={{
                                __html: item.node.title,
                              }}
                            />
                          </h4>
                        </div>
                      </div>
                      <hr className="my-4" />
                    </div>
                  )
                )
              })}
              </div> 
            </div>
            {data.wpgraphql.post.blog.blogField.ghostWriter.bio ? (
              <div className="col-lg-8">
                <div className="row bio-row my-3">
                  <div
                    id={data.wpgraphql.post.blog.blogField.ghostWriter.name
                      .replaceAll(" ", "-")
                      .toLowerCase()}
                    className="ghost-writer-bio card"
                  >
                    <div className="row">
                      {data.wpgraphql.post.blog.blogField.ghostWriter.image ? (
                        <div className="col-lg-2">
                          <div className="ghost-writer-pic">
                            <img
                              src={
                                data.wpgraphql.post.blog.blogField.ghostWriter
                                  .image.sourceUrl
                              }
                              alt={
                                data.wpgraphql.post.blog.blogField.ghostWriter
                                  .image.altText
                              }
                            />
                          </div>
                        </div>
                      ) : null}
                      <div className="col-lg-10">
                        <div className="bio-content">
                          <h4 className="text-blue">
                            {
                              data.wpgraphql.post.blog.blogField.ghostWriter
                                .name
                            }
                          </h4>
                          <div id="ghostWriterBio">
                            <p
                            style={isOpen ? null : paragraphStyles} ref={ref} >
                              {data.wpgraphql.post.blog.blogField.ghostWriter.bio}
                            </p>
                            <Button 
                            className="ghost-writer-btn"
                            onClick={() => setIsOpen(!isOpen)}>
                              {isOpen ? 'read less' : 'read more'}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          {/* <div className="row"></div> */}
          {!isCampaignPost(data.wpgraphql.post.slug) && (
            <div className="row mt-5">
              <div className="col-lg-8">
                <div className="row justify-content-between post-nav">
                  {!!previousBlogPost && (
                    <div className="col-lg-6 col-6">
                        <div className="col-12 blog-nav-txt prev-nav">
                          <h3 className="text-sm mb-3">
                            <FontAwesomeIcon icon="chevron-left" />
                            &nbsp;PREVIOUS
                          </h3>
                        </div>
                        <Link
                          to={"/blog/" + previousBlogPost.node.slug + "/"}
                          className="btn-link">
                        <div key={previousBlogPost.node.id} className="card">
                          <div className="row align-items-center">
                            <div className="col-4">
                              {previousBlogPost.node.featuredImage ? (
                                  <img
                                    src={
                                      previousBlogPost.node.featuredImage.node
                                        .sourceUrl
                                    }
                                    alt={
                                      previousBlogPost.node.featuredImage.node
                                        .altText
                                    }
                                  />
                              ) : (
                                  <img src={defaultImg} alt={"DefaultImage"} />
                              )}
                            </div>
                            <div className="col-8">
                              <h4 className="text-blue">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: previousBlogPost.node.title,
                                  }}
                                />
                              </h4>
                            </div>
                          </div>
                        </div>
                        </Link>
                    </div>
                  )}
                  {!!nextBlogPost && (
                    <div className="col-lg-6 col-6">
                      <div className="col-12 blog-nav-txt next-nav">
                        <h3 className="text-sm mb-3 text-right">
                          UP NEXT&nbsp;
                          <FontAwesomeIcon icon="chevron-right" />
                        </h3>
                      </div>
                        <Link
                          to={"/blog/" + nextBlogPost.node.slug + "/"}
                          className="btn-link"
                         >
                          <div key={nextBlogPost.node.id} className="card">
                            <div className="row align-items-center">
                              <div className="col-8">
                                <h4 className="text-blue text-right">
                                  <Link
                                    to={"/blog/" + nextBlogPost.node.slug + "/"}
                                    dangerouslySetInnerHTML={{
                                      __html: nextBlogPost.node.title,
                                    }}
                                  />
                                </h4>
                              </div>
                              <div className="col-4">
                                {nextBlogPost.node.featuredImage ? (
                                    <img
                                      src={
                                        nextBlogPost.node.featuredImage.node
                                          .sourceUrl
                                      }
                                      alt={
                                        nextBlogPost.node.featuredImage.node.altText
                                      }
                                    />
                                ) : (
                                    <img src={defaultImg} alt={"DefaultImage"} />
                                )}
                              </div>
                            </div>
                          </div>
                        </Link>
                    </div>
                  )} 
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body
          className="p-4 mt-5 text-center"
          dangerouslySetInnerHTML={{ __html: message }}
        />
        <Modal.Footer className="border-0">
          <Button variant="primary mb-3 mx-auto" onClick={handleClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  )
}

export default BlogPage

export const query = graphql`
  query($databaseId: ID!) {
    wpgraphql {
      post(id: $databaseId, idType: DATABASE_ID) {
        id
        title
        date
        content(format: RENDERED)
        excerpt(format: RENDERED)
        featuredImage {
          node {
            altText
            title(format: RENDERED)
            mediaItemUrl
            slug
          }
        }
        categories {
          edges {
            node {
              name
            }
          }
        }
        blog {
          fieldGroupName
          blogField {
            fieldGroupName
            ghostWriter {
              name
              image {
                sourceUrl
              }
              bio
              fieldGroupName
            }
            fieldGroupName
            private
          }
        }
      }

      posts(first: 1000, where: { orderby: { order: DESC, field: DATE } }) {
        edges {
          node {
            id
            title
            slug
            date
            featuredImage {
              node {
                altText
                sourceUrl
              }
            }
            blog {
              blogField {
                private
              }
            }
          }
        }
      }
    }
  }
`
